const actions = (dispatch) => {
  return {
    setMessage: (message) => {
      dispatch({
        type: "MESSAGE_SET",
        message,
      });
    },
  };
};
export default actions;
