import React, { useEffect } from "react";
import LanguageSelector from "./components/LanguageSelector";
import Router from "./Router";
import Message from "./components/Message";
// import Dictionary from "./components/Dictionary";
import getContentfulData from "./components/Dictionary";
import "./App.css";
import { useAppContext } from "./context/AppContext";

function App() {
  const {
    state: { locale },
    dispatch,
  } = useAppContext();
  useEffect(() => {
    if (locale) {
      getContentfulData(locale).then((res) => {
        dispatch({
          type: "DICTIONARY_SET",
          dictionary: res,
        });
      });
    }
  }, [locale, dispatch]);
  return (
    <>
      {/* <Dictionary /> */}
      <LanguageSelector />
      <div className="various-pages">
        <div className="page login">
          <div className="main-content">
            <Router />
            <Message />
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
