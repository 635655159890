import { AppContextProvider } from "./context/AppContext";
import App from "./App";

export default function AppContextWrapper() {
  return (
    <AppContextProvider>
      <div className="App">
        <App />
      </div>
    </AppContextProvider>
  );
}
