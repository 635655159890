import { useEffect, useState } from "react";
import { useAppContext } from "../context/AppContext";
import { getLanguages } from "../api";

export default function LanguageSelector() {
  // Context
  const {
    state: { locale },
    dispatch,
  } = useAppContext();

  // State
  const [languages, setLanguages] = useState([]);

  useEffect(() => {
    getLanguages().then((res) => {
      const { recordset } = res;
      const listOfLanguageLocales = ["en-US", "zh-Hans-HK"];
      const filteredLanguages = recordset.filter(({ locale }) =>
        listOfLanguageLocales.includes(locale)
      );
      setLanguages(filteredLanguages);
    });
  }, []);

  const handleChange = (e) => {
    const { value } = e.target;
    const { locale } = languages.find(({ name }) => name === value);

    dispatch({
      type: "LOCALE_SET",
      locale,
    });
  };

  // Render language selector after languages have been retrieved from db.
  if (!languages.length) {
    return null;
  }

  return (
    <>
      <label
        htmlFor="lang-menu"
        aria-label="Language Selection"
        aria-haspopup="true"
      ></label>
      <select
        onChange={handleChange}
        value={(() => {
          const { name } = languages.find((lang) => {
            return lang.locale === locale;
          });

          return name;
        })()}
      >
        {languages.map((language, index) => (
          <option key={index} value={language.name}>
            {language.name}
          </option>
        ))}
      </select>
    </>
  );
}
