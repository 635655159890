const THEME_SET = "THEME_SET";
const LOCALE_SET = "LOCALE_SET";
const DICTIONARY_SET = "DICTIONARY_SET";
const MESSAGE_SET = "MESSAGE_SET";
const MESSAGE_CLEAR = "MESSAGE_CLEAR";
const RESET = "RESET";

export const initialState = {
  theme: "light",
  locale: "zh-Hans-HK",
  message: null,
  dictionary: null,
};

const reducer = (state, action) => {
  const newState = { ...state };
  switch (action.type) {
    // THEME ACTIONS
    case THEME_SET:
      console.log(`Theme set to ${action.theme}`);
      return { ...newState, theme: action.theme };
    // LOCALE ACTIONS
    case LOCALE_SET:
      console.log(`Locale set to ${action.locale}`);
      return { ...newState, locale: action.locale };
    // MESSAGE ACTIONS
    case MESSAGE_SET:
      console.log(`Message set - ${action.message}`);
      return { ...newState, message: action.message };
    case MESSAGE_CLEAR:
      console.log(`Message cleared`);
      return { ...newState, message: null };

    // DICTIONARY ACTIONS
    case DICTIONARY_SET:
      console.log(`Dictionary set`);
      return { ...newState, dictionary: action.dictionary };

    // UNIVERSAL ACTIONS
    case RESET:
      console.log(`context reset`);
      return { ...initialState };

    default:
      console.error(`Unhandled action type in AppContext: ${action.type}`);
      return { ...newState };
  }
};

export default reducer;
