import { getEntryByLocale } from "../api/contentful";

export default function getContentfulData(locale) {
  return Promise.all([
    getEntryByLocale("UzKX9sU0wLC4vqjAmayzH", locale), // login page
    getEntryByLocale("7wRVF5Wyo9ZsA5I40kIZdY", locale), // forgot page
    getEntryByLocale("4Ma6dPYVUwZpsohT3Hi8H5", locale), // reset page
    getEntryByLocale("1KsGscO93HK8GFqnX5EbrA", locale), // password
  ]).then(([login, forgot, reset, password]) => {
    return {
      login: parseContentfulList(login),
      forgot: parseContentfulList(forgot),
      reset: parseContentfulList(reset),
      password: parsePasswordData(password),
    };
  });
}
function parsePasswordData(data) {
  if (!data?.fields?.pdfReferences?.length) return;
  const {
    fields: { title, pdfReferences },
  } = data;
  return [
    title,
    ...pdfReferences.map(
      ({ fields: { descriptiveTitle } }) => descriptiveTitle
    ),
  ];
}

function parseContentfulList(data) {
  if (!data?.fields?.listArray?.length) return;
  const {
    fields: { listArray },
  } = data;
  return listArray.map(
    ({ fields: { textString, name } }) => textString || name // textString handles singletext model and name handles Input model
  );
}
