import { useAppContext } from "../context/AppContext";

export default function Message() {
  const {
    state: { message },
    dispatch,
  } = useAppContext();

  function close() {
    dispatch({
      type: "MESSAGE_CLEAR",
    });
  }

  function messageClasses() {
    const classString = "global-alert fade-in";
    switch (message.type) {
      case "info":
        return classString + " blue";
      case "success":
        return classString + " green";
      default:
        return classString + " orange";
    }
  }
  if (!message) return null;
  return (
    <div
      className={messageClasses()}
      style={{ position: "relative", top: 0, left: 0 }}
    >
      <svg
        width="27"
        height="25"
        viewBox="0 0 27 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M12.5713 15.2619V8.96191H14.4473V15.2619H12.5713ZM12.5713 18.9999V16.8859H14.4473V18.9999H12.5713Z"></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M24.3252 20.75L14.3659 3.5C13.981 2.83333 13.0188 2.83334 12.6339 3.5L2.67458 20.75C2.28968 21.4167 2.77081 22.25 3.5406 22.25H23.4592C24.229 22.25 24.7101 21.4167 24.3252 20.75ZM16.098 2.5C14.9433 0.500002 12.0565 0.499998 10.9018 2.5L0.942533 19.75C-0.212168 21.75 1.2312 24.25 3.5406 24.25H23.4592C25.7686 24.25 27.212 21.75 26.0573 19.75L16.098 2.5Z"
        ></path>
      </svg>

      <span className="alert-message">
        <p>{message.text}</p>
        {/* <p className="secondary-msg">
              If you continue to see this error, please contact customer support
              at <a>888.888.8888</a>
            </p> */}
      </span>

      <button onClick={close}>
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7 7L1 12.9977L7 7ZM7 7L1 1L7 7ZM7 7L12.9977 1L7 7ZM7 7L13 13L7 7Z"
            stroke-width="2"
          ></path>
        </svg>
      </button>
    </div>
  );
}
