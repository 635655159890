import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppContext } from "../context/AppContext";
import { forgotPassword } from "../api";

export default function Forgot() {
  const navigate = useNavigate();
  const {
    state: {
      locale,
      dictionary: { forgot: c_forgot },
    },
    actions: { setMessage },
  } = useAppContext();
  const [id, set_id] = useState("");
  const [email, set_email] = useState("");
  const [
    text1,
    text_subtitle,
    text_distId,
    text_email,
    text_cancel,
    text_successMessage,
    text_errorMessage,
    text_submit,
  ] = c_forgot;
  const text_forgotPassword = text1.slice(0, -1); // remove '?'

  function onSubmit(e) {
    e.preventDefault();
    console.log({ id, email }, " submitted");
    forgotPassword({ id, email, locale })
      .then((response) => {
        setMessage({ text: text_successMessage, type: "info" });
      })
      .catch(() => {
        setMessage({ text: text_errorMessage });
      })
      .finally(() => navigate("/login"));
  }

  return (
    <section>
      <h1 className="text-center mb-40">{text_forgotPassword}</h1>
      <form className="form-max flex-20 column" onSubmit={onSubmit}>
        <p>{text_subtitle}</p>
        <div className="input-wrapper">
          <input
            id="dist-id"
            name="dist-id"
            type="text"
            required
            onChange={(e) => {
              set_id(e.target.value);
            }}
          />
          <label htmlFor="dist-id" aria-label="dist-id">
            {text_distId}
          </label>
        </div>
        <div className="input-wrapper">
          <input
            id="email"
            name="email"
            type="email"
            required
            onChange={(e) => {
              set_email(e.target.value);
            }}
          />
          <label htmlFor="email" aria-label="email">
            {text_email}
          </label>
        </div>
        <div className="flex-20 mt-20 justify-center">
          <Link to="/login">
            <button className="secondary" type="button">
              {text_cancel}
            </button>
          </Link>
          <button className="mt-40" type="submit">
            {text_submit}
          </button>
        </div>
      </form>
    </section>
  );
}
