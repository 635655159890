import axios from "axios";
import mockapi from "../test/mockapi";
import config from "../config";

function backendRequest(url, data, method) {
  if (!url) throw new Error("requests can not be made without a url");
  const axiosConfig = {
    timeout: 5000,
    url: config.REACT_APP_API_URL + url,
    data,
    method,
  };
  return axios(axiosConfig)
    .then(({ data }) => {
      if (!data) throw new Error(`no data retrieved from call - ${url}`);
      return data;
    })
    .catch((e) => {
      throw new Error(`server error in - ${url}`);
    });
}

export async function getGuid(repDID) {
  if (!repDID) throw new Error("No RepDID");
  // const repId = await backendRequest(
  //   "/api/validateByGuidMigrate",
  //   { guid },
  //   "post"
  // ).then(({ RepDID }) => RepDID);
  console.log("sample guid, test api");
  return "xxxx";
}
export async function validateGuid(guid) {
  if (!guid) throw new Error("No GUID");
  const repId = await backendRequest(
    "/api/validateByGuidMigrate",
    { guid },
    "post"
  ).then(({ RepDID }) => RepDID);
  return repId;
}
export async function getLanguages() {
  const languages = await backendRequest("/api/language");
  return languages;
}
export async function login(form) {
  const { password } = form;
  const repDID = form?.["dist-id"];
  return await backendRequest(
    "/api/processChinaLogin",
    { repDID, password },
    "post"
  );
}
export async function resetPassword(repDID, token, password) {
  const response = await backendRequest(
    `/api/chinaPasswordReset`,
    {
      repDID,
      token,
      password,
    },
    "post"
  );
  return response;
}
export async function forgotPassword({ id, email, locale }) {
  const response = await backendRequest(
    `/api/chinaForgotPassword`,
    { email, lang: locale, repDID: id },
    "post"
  );
  return response;
}
