import { useState, useEffect } from "react";
import { useAppContext } from "../context/AppContext";
import ValidIcon from "../icons/valid";

export default function Password(props) {
  const {
    state: {
      dictionary: { password: contentfulData },
    },
  } = useAppContext();
  const { isValid = () => {}, onChange, value: valueFromProps } = props;
  const id = "PASSWORD";
  const [value, set_value] = useState(valueFromProps);
  const [secure, setSecure] = useState(true);
  const [showRequirements, setshowRequirements] = useState(false);

  function handleChange(e) {
    const { value } = e.target;
    onChange(value);
    set_value(value);
  }

  function toggleShowRequirements() {
    if (!showRequirements) setshowRequirements(true);
    if (validate()) {
      setshowRequirements(false);
    }
  }

  function validate() {
    if (checkLength() && hasLowercase() && hasUppercase() && hasNumber())
      return true;
    return false;
  }

  useEffect(() => {
    if (validate()) isValid(true);
    else isValid(false);
  });

  function checkLength() {
    return value?.length >= 8;
  }
  function hasUppercase() {
    return value.match("[A-Z]+");
  }
  function hasLowercase() {
    return value.match("[a-z]+");
  }
  function hasNumber() {
    return value.match("[0-9]+");
  }
  const [
    label,
    show,
    hide,
    validateUppercase,
    validateLowercase,
    validateNumber,
    validateCharacters,
  ] = contentfulData;

  return (
    <div className="password-wrapper">
      <div className="input-wrapper">
        <input
          type={secure ? "password" : "text"}
          id={id}
          onChange={(e) => handleChange(e)}
          value={value}
          name={id}
          onFocus={(e) => toggleShowRequirements()}
          onBlur={(e) => toggleShowRequirements()}
        />
        <label htmlFor={id} aria-label={id}>
          {label}
        </label>
        <button
          onClick={(_) => setSecure(!secure)}
          type="button"
          id="passwordShow"
        >
          {secure ? show : hide}
        </button>
      </div>

      <ul className={`validation-list ${showRequirements ? "active" : ""}`}>
        <Validation
          id="check-length"
          text={validateCharacters}
          validationFunction={checkLength}
        />
        <Validation
          id="check-upper"
          text={validateUppercase}
          validationFunction={hasUppercase}
        />
        <Validation
          id="check-lower"
          text={validateLowercase}
          validationFunction={hasLowercase}
        />
        <Validation
          id="check-number"
          text={validateNumber}
          validationFunction={hasNumber}
        />
      </ul>
    </div>
  );
}
function Validation(props) {
  const { id, text, validationFunction } = props;
  return (
    <li id={id}>
      <ValidIcon isValid={validationFunction()} />
      <p>{text}</p>
    </li>
  );
}
