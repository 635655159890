import { useState } from "react";
import { Link } from "react-router-dom";
import { login } from "../api";
import { useAppContext } from "../context/AppContext";
import "./login.css";
import config from "../config";

export default function Login() {
  const {
    state: {
      dictionary: { login: c_login },
    },
    actions: { setMessage },
  } = useAppContext();
  const [form, set_form] = useState({ "dist-id": "", password: "" });
  const [
    text_login,
    text_distId,
    text_forgotPassword,
    text_failToLoginMessage,
    text_submit,
  ] = c_login;
  console.log(c_login);
  function handleChange(e) {
    const { id, value } = e.target;
    set_form({ ...form, [id]: value });
  }

  function handleSubmit(e) {
    e.preventDefault();
    console.log("submitted", form);
    login(form)
      .then((authResponse) => {
        const url = authResponse.url || `${config.REACT_APP_REDIRECT_URL}/Account/SeamlessAuthentication/${authResponse.Token}`;
        window.location.replace(url);
      }).catch((e) => {
        console.error(e);
        setMessage({ text: text_failToLoginMessage });
      });
  }
  return (
    <section>
      <h1 className="text-center mb-40">{text_login}</h1>
      <form
        className="form-max flex-20 column align-center"
        onSubmit={handleSubmit}
      >
        <div className="input-wrapper">
          <input
            id="dist-id"
            name="dist-id"
            type="text"
            required
            onChange={handleChange}
          />
          <label htmlFor="dist-id" aria-label="dist-id">
            {text_distId}
          </label>
        </div>
        <Password onChange={handleChange} />

        {/* <div className="blue--switch--container">
                <label className="blue--switch" htmlFor="terms" tabIndex="0">
                  <input
                    type="checkbox"
                    id="terms"
                    name="terms"
                    aria-label="remember me"
                    // checked
                  />
                  <span className="slider round">
                    <svg
                      width="16"
                      height="13"
                      viewBox="0 0 16 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.23242 6.64611L6.14441 11L14.575 1"
                        strokeWidth="2"
                      />
                    </svg>
                  </span>
                </label>
                <span className="switch-text">
                  <label htmlFor="terms">Remember Me</label>
                </span>
              </div> */}

        <button className="mt-40" type="submit">
          {text_submit}
        </button>

        <Link to="/pw/forgot">
          <span>{text_forgotPassword}</span>
        </Link>
      </form>
    </section>
  );
}
function Password(props) {
  const {
    state: {
      dictionary: { password: c_password },
    },
  } = useAppContext();
  const [text_password, text_show, text_hide] = c_password;
  const { onChange } = props;
  const [hidden, set_hidden] = useState(true);
  return (
    <div className="password-wrapper">
      <div className="input-wrapper">
        <input
          id="password"
          name="password"
          type={hidden ? "password" : "text"}
          required
          onChange={onChange}
        />
        <label htmlFor="password" aria-label="password">
          {text_password}
        </label>
        <button
          type="button"
          id="passwordShow"
          onClick={(e) => set_hidden(!hidden)}
        >
          {hidden ? text_show : text_hide}
        </button>
      </div>
    </div>
  );
}
